@import url("https://fonts.googleapis.com/css?family=Shadows+Into+Light|Source+Sans+Pro:400,700");

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4 {
  font-size:calc(12px + 3vw);
  font-weight: 700;
}

.script-font {
  font-family: "Shadows Into Light", cursive;
  font-weight: 500;
}

a {
  color: rgb(0, 180, 217);
  text-decoration: none;
}

a:hover {
  color: rgb(0, 180, 217);
  text-decoration: underline;
}
