@import url(https://fonts.googleapis.com/css?family=Shadows+Into+Light|Source+Sans+Pro:400,700);
body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4 {
  font-size:calc(12px + 3vw);
  font-weight: 700;
}

.script-font {
  font-family: "Shadows Into Light", cursive;
  font-weight: 500;
}

a {
  color: rgb(0, 180, 217);
  text-decoration: none;
}

a:hover {
  color: rgb(0, 180, 217);
  text-decoration: underline;
}

.App {
  text-align: center;
  line-height: 25px;
}



.hero-heading {
  font-size:calc(12px + 2.2vw);
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.1rem;
  line-height: 1vw;
  margin-bottom: 25px;
}

.head-title {
  color: #fff;
  font-size:calc(12px + 5.5vw);
  letter-spacing: 1vw;
  text-transform: uppercase;
  font-weight: 700;
}

.social-icon a {
  color: #000;
  background: #fff;
  padding: 8px;
  display: inline-flex;
  border-radius: 100%;
  font-size: 30px;
  margin: 18px;
  transition: all 0.2s ease-in-out 0.1s;
  -moz-transition: all 0.2s ease-in-out 0.1s;
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  -o-transition: all 0.2s ease-in-out 0.1s;
}

.social-icon a:hover {
  color: #fff;
}

.social-icon .facebook {
  color: #3b5999;
}
.social-icon .facebook:hover {
  background: #3b5999;
}

.social-icon .instagram:hover {
  background: #e1306c;
}

img {
  width: 350px;
  height: 350px;
  margin: 30px;
  border-radius: 15px;
}

div.GalleryContainer {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#portfolio .shot-item {
  margin-right: 30px;
}

.shot-item {
  border-radius: 4px;
  background: #fff;
  position: relative;
}

.shot-item .overlay {
  position: absolute;
  width: 350px;
  height: 350px;
  margin: 30px;
  left: 0;
  top: 0;
  border-radius: 15px;
  background: rgba(0, 180, 217, 0.6);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.overlay {
  opacity: 0;
}

.overlay .icons i {
  height: 42px;
  width: 42px;
  line-height: 42px;
  color: rgb(0, 180, 217);
  margin: -30px;
  padding-top: 5px;
  padding-left: 1px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  text-align: center;
  font-size: 25px;
  transition: all 0.5s ease-in-out;
  background: #fff;
  border-radius: 4px;
}

.overlay i:hover {
  color: #e1306c;
}

.overlay .link {
  position: absolute;
  left: 50%;
  margin-left: 10px;
  top: 50%;
  color: #fff;
}

.shot-item:hover .overlay {
  opacity: 0.9;
}

a:not([href]):not([tabindex]) {
  color: #fff;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: #fff;
}

*.subheading {

    color: #252525;
    flex: auto;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 8px;
    padding: 0 10px;
}
div.footer {
  box-sizing: border-box;
  background-color: rgb(18, 18, 20);
  background-image: url(/static/media/stressed-linen.a7da16c1.png);
  padding: 30px;
}

p.footer {
  text-align: center;
  font-size:calc(10px + 0.3vw);
  letter-spacing: 0.09vw;
  color: #fff;
}

